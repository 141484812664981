import React from 'react'
import Servicios from '../components/Servicios/Servicios'
import Footer from '../components/Footer/Footer'

function ContactoScreen() {
    return (
        <>
            <main>
                <Servicios/>
            </main>
            <Footer/>
        </>
    )
}

export default ContactoScreen
